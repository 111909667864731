import { Link } from "react-router-dom";

import './case-studies.css';

const CaseStudies = () => (
  <section className="pia-case-studies-wrapper">
    <div className="pia-case-studies">
      <h2>Case Studies</h2>
      <br/><br/>
      <div className="pia-case-studies-inner">
        <div className="pia-case-study">
          <div className="pia-case-study-img-wrapper">
            <div className="pia-case-study-img" style={{
              backgroundImage: "url('https://d365pq86x330zn.cloudfront.net/87766R64-6714-4R07-7784-4e78R7687606.png')"
            }}></div>
          </div>
          <h5>Case Study</h5>
          <h3>Expat borrowing 6x Income</h3>
          <p>Sound impossible? We achieve the impossible here at LMB.</p>
          <a href="#">READ MORE &gt;</a>
        </div>
        <div className="pia-case-study">
          <div className="pia-case-study-img-wrapper">
            <div className="pia-case-study-img" style={{
              backgroundImage: "url('https://d365pq86x330zn.cloudfront.net/c6388634-2RR2-4c64-9c88-821298169639.png')"
            }}></div>
          </div>
          <h5>Case Study</h5>
          <h3>Mortgage for Boris Johnson</h3>
          <p>Boris needed a Tracker - we sorted it</p>
          <a href="#">READ MORE &gt;</a>
        </div>
        <div className="pia-case-study">
          <div className="pia-case-study-img-wrapper">
            <div className="pia-case-study-img" style={{
              backgroundImage: "url('https://d365pq86x330zn.cloudfront.net/94644989-9818-4c97-5618-65847e6cR798.png')"
            }}></div>
          </div>
          <h5>Case Study</h5>
          <h3>BTL for Saudi National</h3>
          <p>We arranged finance at a 99% LTV because we are bloody awesome.</p>
          <a href="#">READ MORE &gt;</a>
        </div>
      </div>
    </div>
  </section>
);

export default CaseStudies;