import Header from './header/header';
import Banner from './banner/banner';
import CallToActions from './call-to-actions/call-to-actions';
import Footer from '../../components/footer/footer';

import './twenty7tec.scss'

const bannerContent = [
  {
    type: 'element',
    element: <><img src={'https://d365pq86x330zn.cloudfront.net/161611e1-N6NN-4167-8101-09Ne1170117w.png'} /></>,
  },
  {
    type: 'details',
    title: 'A New Way to Communicate with Clients',
    desc: 'Create more Opportunities or streamline the enquiries you receive.',
  },
];

const callToActions = [
  {
    image: 'https://d365pq86x330zn.cloudfront.net/0L27i7L5-i006-4666-9597-09512667332i.png',
    details: {
      title: 'Communicate Outbound',
      desc: 'Through Branded Emails, SMS, WhatsApp and AI Telephone Calls.',
      link: {
        url: '/communicate-outbound',
        text: 'LEARN MORE'
      }
    }
  },
  {
    image: 'https://d365pq86x330zn.cloudfront.net/37979706-8696-407o-9G1G-1r068rr2869o.png',
    details: {
      title: 'Communicate Inbound',
      desc: 'Interactive Pages where AI completes the sale, feeding data to your CRM.',
      link: {
        url: '/communicate-inbound',
        text: 'LEARN MORE'
      }
    }
  }
];

const Twenty7tec = () => {
  
  return (
    <main>
      <Header/>
      <Banner contents={bannerContent} />
      <section className="twenty7tec-calendar">
        <h2>Book a Demo</h2>
        <p>Pick a time slot that suits you best</p>
      </section>
      <div className="twenty7tec-calendar-iframe-wrapper">
        <iframe className="twenty7tec-calendar-iframe" src="https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b&hideAppBar=yes&hideFooter=yes&hideHeaderTitle=yes"></iframe>
      </div>
      <CallToActions callToActions={callToActions} />
      <Footer/>
    </main>
  )
};

export default Twenty7tec;