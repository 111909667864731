import Fade from 'react-reveal/Fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import './customer.css';

const JoinUs = () => (<>
  <section className="customer-wrapper">
    <div className="customer">
      <Fade left>
        <Swiper autoplay={{ delay: 2500, disableOnInteraction: false, }} loop={true} slidesPerView={1} modules={[Autoplay]}
        breakpoints={{
          '320': {
            slidesPerView: 1
          },
          '768': {
            slidesPerView: 2
          },
          '1024': {
            slidesPerView: 5
          },
        }}>
          <SwiperSlide>
            <div className="cus-img-wrap" style={{padding: '20px 10px'}}>
              <img src="https://d365pq86x330zn.cloudfront.net/6-297421--674-4i37-3761-fi6499999767.png" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cus-img-wrap">
              <img src="https://d365pq86x330zn.cloudfront.net/4861999f-1611-4464-1-66-2f1i8969616f.png" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cus-img-wrap">
              <img src="https://d365pq86x330zn.cloudfront.net/46998919-f-43-4917-3394-949464936414.png" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cus-img-wrap">
              <img src="https://d365pq86x330zn.cloudfront.net/-38688f8-3981-4193-6111-9814i69164i9.png" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cus-img-wrap">
              <img src="https://d365pq86x330zn.cloudfront.net/91161996-9999-4i69-1439-99646066144i.png" />
            </div>
          </SwiperSlide>
        </Swiper>
      </Fade>
      <br/>
      <Fade right>
        <Swiper autoplay={{ delay: 2500, disableOnInteraction: false, }} loop={true} slidesPerView={1} modules={[Autoplay]}
        breakpoints={{
          '320': {
            slidesPerView: 1
          },
          '768': {
            slidesPerView: 2
          },
          '1024': {
            slidesPerView: 4
          },
        }}>
          <SwiperSlide>
            <div className="cus-img-wrap">
              <img src="https://d365pq86x330zn.cloudfront.net/6816f145-676--4684-7ff4-6979f75i9646.png" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cus-img-wrap">
              <img src="https://d365pq86x330zn.cloudfront.net/66490916-4179-4469-447i-4-8-060661-4.png" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cus-img-wrap">
              <img src="https://d365pq86x330zn.cloudfront.net/141-i6-9-9ff4-4176-4761-84f99166f967.png" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cus-img-wrap">
              <img src="https://d365pq86x330zn.cloudfront.net/119f9692-8616-4188-6686-6622849664i8.png" />
            </div>
          </SwiperSlide>
      </Swiper>
      </Fade>
      <hr/>
    </div>
    <div className="customer-rating">
      <h2>Here’s what our clients think</h2>
      <img src="https://d365pq86x330zn.cloudfront.net/29622099-72c1-4c77-7669-9c9661190994.png" />
      <div className="customer-ratings">
        <div className="customer-single-rating">
          <div className="customer-single-rating-details">
            <div className="customer-single-rating-img" style={{
              backgroundImage: "url('https://d365pq86x330zn.cloudfront.net/436576l4-4571-47l6-7466-767l177474l7.png')"
            }}></div>
            <div className="customer-single-rating-info">
              <label>Amy Witney</label>
              <span>UK Resident</span>
            </div>
          </div>
          <p>Sophie is absolutely amazing. We are very happy with what she has done for us. She is very professional, very competent, very smart and she was wholeheartedly devoted to finding us the best deal and she achieved it. She is also very trustworthy and kind hearted. This is the second time that Julia has helped us.</p>
        </div>
        <div className="customer-single-rating">
          <div className="customer-single-rating-details">
            <div className="customer-single-rating-img" style={{
              backgroundImage: "url('https://d365pq86x330zn.cloudfront.net/l6663EE3-2l36-4861-2266-620460277724.png')"
            }}></div>
            <div className="customer-single-rating-info">
              <label>Sarah Wayne</label>
              <span>UK Resident</span>
            </div>
          </div>
          <p>James & Emmie were amazing, and helped us through every part of the process! They are very proactive and always on top of latest news and upcoming changes in mortgages. Even in a super busy period with everyone applying for mortgages before rates go up again, they were able to work with me on a tight timeline and made sure I get the best possible rate.</p>
        </div>
        <div className="customer-single-rating">
          <div className="customer-single-rating-details">
            <div className="customer-single-rating-img" style={{
              backgroundImage: "url('https://d365pq86x330zn.cloudfront.net/46ll5855-1788-4771-5680-6l186ll16657.png')"
            }}></div>
            <div className="customer-single-rating-info">
              <label>Harper Lee</label>
              <span>UK Resident</span>
            </div>
          </div>
          <p>Lond Broker were amazing -- they explained everything at the beginning of the process, found some excellent mortgage offers for me, and were very responsive and communicative. Can highly recommend them.</p>
        </div>
        <div className="customer-single-rating">
          <div className="customer-single-rating-details">
            <div className="customer-single-rating-img" style={{
              backgroundImage: "url('https://d365pq86x330zn.cloudfront.net/7766l716-l773-4716-13E9-316371969896.png')"
            }}></div>
            <div className="customer-single-rating-info">
              <label>Matt Damon</label>
              <span>UK Resident</span>
            </div>
          </div>
          <p>Matt, Betsy and team were a pleasure and delight to work with. They picked up our calls whenever we had a silly, mad question meanwhile keeping us rightfully informed and calm. Would highly recommend the whole team.</p>
        </div>
      </div>
    </div>
  </section>
</>);

export default JoinUs;