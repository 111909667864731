import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import GetNextDates from 'get-next-dates';

import 'react-calendar/dist/Calendar.css';
import './content.css';

const socket = new WebSocket('wss://dev-socket.meetparker.io');
let connectionId = "";
  
socket.onmessage = (data) => {
  if (JSON.parse(data.data).connectionId) {
    connectionId = JSON.parse(data.data).connectionId;
  }
}

const Content = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [ isBooked, setIsBooked ] = useState(false);
  const [ emailReq, setEmailReq ] = useState({
    book_firstName: "",
    book_lastName: "",
    book_email: "",
    book_company: "",
    book_datetime: "",
  });
  const [ agreeCB, setAgreeCB ] = useState(false);
  const [ availableDates, setAvailableDates ] = useState([]);
  const [ selectedAppointmentDate, setSelectedAppointmentDate ] = useState("");
  const [ selectedAppointmentDayPart, setSelectedAppointmentDayPart ] = useState("Morning");
  const [ selectedAppointmentTime, setSelectedAppointmentTime ] = useState("09:00");
  const [ availableTime ] = useState(
    [{
      partOfTheDay: 'Morning',
      time: ['09:00', '10:00', '11:00']   
    },{
      partOfTheDay: 'Afternoon',
      time: ['12:00', '13:00', '14:00', '15:00']   
    },{
      partOfTheDay: 'Evening',
      time: ['16:00', '17:00']   
    }]
  );

  const addNewDates = () => {
    let getNextDateToday = GetNextDates(7,availableDates[availableDates.length - 1].dateFull);
    let getNextDateTodayMod = [];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    for (let a = 0; a < getNextDateToday.length; a++) {
      let activeDate = new Date(getNextDateToday[a]);
      if(activeDate.getDay() !== 0 && activeDate.getDay() !== 6) {
        getNextDateTodayMod.push({
          dateFull: getNextDateToday[a],
          day: days[activeDate.getDay()],
          month: months[activeDate.getMonth()],
          year: activeDate.getFullYear(),
          date: activeDate.getDate()
        });
      }
    }

    setAvailableDates([...availableDates, ...getNextDateTodayMod]);
  }

  const ordinalSuffixOf = (i) => {
    let j = i % 10,  k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const submitBookADemo = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    const selectedAppointmentDateClean = new Date(selectedAppointmentDate);
    if (connectionId.trim() !== "") {
      socket.send(JSON.stringify({
        Entries: [{
          Detail: {
            connectionId,
            type: "send-email",
            secure_email_from: "hello@meetparker.co.uk",
            secure_email:  "hello@meetparker.co.uk", // `${emailReq.book_email}`
            secure_email_template: "mp_book_a_demo",
            secure_email_subject: "Meet Parker: Book A Demo Entry",
            custom_fields: {
              ...emailReq,
              book_datetime: `${days[selectedAppointmentDateClean.getDay()]} ${ordinalSuffixOf(selectedAppointmentDateClean.getDate())} ${months[selectedAppointmentDateClean.getMonth()]} at ${selectedAppointmentTime.replace(':','')}`
            }
          },
          Time: new Date(),
          Source: `mp-amplify-website`,
          DetailType: `web.to.api`,
          EventBusName: `mp-global-exchange-dev`,
        }],
      }));
      setIsBooked(true);
    }
  }

  useEffect(() => {
    window.location.replace("https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b");
    let getNextDateToday = GetNextDates(7, GetNextDates(1)[0]);
    let getNextDateTodayMod = [];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    for (let a = 0; a < getNextDateToday.length; a++) {
      let activeDate = new Date(getNextDateToday[a]);
      if(activeDate.getDay() !== 0 && activeDate.getDay() !== 6) {
        getNextDateTodayMod.push({
          dateFull: getNextDateToday[a],
          day: days[activeDate.getDay()],
          month: months[activeDate.getMonth()],
          year: activeDate.getFullYear(),
          date: activeDate.getDate()
        });
      }
    }
    
    setAvailableDates([...getNextDateTodayMod]);
    setEmailReq({
      ...emailReq,
      book_firstName: searchParams.get("_firstname"),
      book_lastName: searchParams.get("_lastname"),
      book_email: searchParams.get("_email"),
    })
  }, []);

  return (<>
    <section className="book-a-demo-form-wrapper">
      <div className="book-a-demo-form">
        <div className="book-a-demo-form-text">
          <br/><br/>
          <h1>Book A Demo</h1>
          <br/><br/>
          <p>Please choose a date and time below, one of the team will then reach out to confirm your appointment and take you through the products we offer at Meet Parker.</p>
        </div>
        <div className="book-a-demo-form-fields">
          <div className="form-row">
            <div className="form-col">
              <div className="form-group">
                <label>First Name</label>
                <input value={emailReq.book_firstName} onChange={({target: {value}}) => {setEmailReq({
                  ...emailReq,
                  book_firstName: value
                })}} />
              </div>
            </div>
            <div className="form-col">
              <div className="form-group">
                <label>Last Name</label>
                <input value={emailReq.book_lastName} onChange={({target: {value}}) => {setEmailReq({
                  ...emailReq,
                  book_lastName: value
                })}} />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <div className="form-group">
                <label>Email</label>
                <input value={emailReq.book_email} onChange={({target: {value}}) => {setEmailReq({
                  ...emailReq,
                  book_email: value
                })}} />
              </div>
            </div>
            <div className="form-col">
              <div className="form-group">
                <label>Company</label>
                <input value={emailReq.book_company} onChange={({target: {value}}) => {setEmailReq({
                  ...emailReq,
                  book_company: value
                })}} />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <div className="appointment-date-wrapper">        
                <div className="appointment-date-row">
                  { availableDates.map((activeAvailableDates, index) => (
                    <div className="appointment-date-col" key={'appointment-date-col-z' + index}>
                      <button
                        className={[
                          "appointment-date-button",
                          selectedAppointmentDate === activeAvailableDates.dateFull ? "active" : ""].join(' ')}
                        onClick={()=>{ 
                          setSelectedAppointmentDate(activeAvailableDates.dateFull) 
                        }}
                      >
                        <h5 className="appointment-date-month">{activeAvailableDates.day}</h5>
                        <h2 className="appointment-date-day">{activeAvailableDates.date}</h2>
                        <h5 className="appointment-date-month">{activeAvailableDates.month}</h5>
                      </button>
                    </div>
                  ))}
                  <div className="appointment-date-col">
                    <button
                      className={[ "appointment-date-button" ].join(' ')}
                      onClick={()=>{ addNewDates() }}
                    >
                      <h5 className="appointment-date-month">&nbsp;</h5>
                      <h2 className="appointment-date-day">&rarr;</h2>
                      <h5 className="appointment-date-month">&nbsp;</h5>
                    </button>
                  </div>
                </div>
                <div className="appointment-time-row">
                  { availableTime.map((activeAvailableTime, index) => (
                    <div className="appointment-date-col">
                      <button
                        className={[
                          "appointment-date-button",
                          selectedAppointmentDayPart === activeAvailableTime.partOfTheDay ? "active" : ""].join(' ')}
                        onClick={()=>{ 
                          setSelectedAppointmentDayPart(activeAvailableTime.partOfTheDay) 
                        }}
                      >
                        <h2 className="appointment-date-day">{activeAvailableTime.partOfTheDay}</h2>
                      </button>
                    </div>
                  ))}
                </div>
                { availableTime.map((activeAvailableTime, index) => (
                  <>
                    { selectedAppointmentDayPart === activeAvailableTime.partOfTheDay ?
                      <div className="appointment-hour-row active">
                        { activeAvailableTime.time.map((activeAvailableTimeSingle, indexe) => (
                          <div className="appointment-date-col">
                            <button 
                              className={[
                                "appointment-date-button",
                                selectedAppointmentTime === activeAvailableTimeSingle ? "active" : ""].join(' ')}
                              onClick={()=>{ 
                                setSelectedAppointmentTime(activeAvailableTimeSingle) 
                              }}
                            >
                              <h2 className="appointment-date-day">{activeAvailableTimeSingle}</h2>
                            </button>
                          </div>
                        ))}
                      </div> : null
                    }
                  </>
                ))}
              </div>
            </div>
            <div className="form-col">
              <div className="checkbox-wrapper">
                <div
                  className={[ "checkbox", agreeCB ? "active" : ""].join(' ')}
                  onClick={() => {setAgreeCB(!agreeCB)}}
                >
                  <div className="checkbox-inner"></div>
                </div>
                <label className="checkbox-label">Tick to agree to our Privacy Policy and Terms of Service</label>
              </div>
              <br/><br/>
              { isBooked ? 
              <div className="form-group">
                <label><h3>All sorted. You will receive an email confirmation shortly.</h3></label>
              </div> : <>
              { (agreeCB && 
                emailReq.book_firstName?.trim() !== "" && 
                emailReq.book_lastName?.trim() !== "" && 
                emailReq.book_email?.trim() !== "" && 
                emailReq.book_company?.trim() !== "" && 
                selectedAppointmentDate !== "") ? 
              <button className="btn btn-success btn-block submit" onClick={() => {submitBookADemo()}} >SUBMIT</button> :
              <button className="btn btn-success btn-block submit disabled">SUBMIT</button> }
              </> }
            </div>
          </div>
        </div>
      </div>
      <div className="lbanner-white-transparent"><div>&nbsp;</div></div>
    </section>
    <section className="book-a-demo-posts-wrapper">
      <div className="book-a-demo-posts">
        <div className="book-a-demo-posts-intro">
          <div className="book-a-demo-posts-intro-img"></div>
          <div className="book-a-demo-posts-intro-text">
            <h2 style={{textTransform: 'none'}}>Create more Sales with our Outbound and Inbound Digital Tools</h2>

            <p>Did you know you can trigger a message to the client through WhatsApp, Facebook. Twitter, Instagram, SMS, Telephone & more?</p>
            {/* <Link to="/business-to-clients" className="btn b2c">VISIT B2C STORE</Link>
            <Link to="/business-to-business" className="btn b2b">VISIT B2B STORE</Link> */}
          </div>
        </div>
      </div>
    </section>
  </>);
};

export default Content;