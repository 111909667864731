import Fade from 'react-reveal/Fade';
import { useState } from "react";
import { Link } from "react-router-dom";

import './join-us.css';

const JoinUs = () => {
  const [email, setEmail] = useState('');
  
  return (<>
  <section className="join-us-wrapper">
    <div className="join-us">
      <Fade left>
      <div className="join-us-input">
        <h2>Join the community</h2>
        <input type="text" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} />
        <a className="fbtn" href={`https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b`}>Subscribe</a>
      </div>
      </Fade>
      <Fade right>
      <div className="join-us-details">
        <div className="join-us-details-inner">
          <div className="join-us-details-single">
            <div className="img">
              <img width="130" src="https://d365pq86x330zn.cloudfront.net/51141148-0roo-4119-811r-Gr3606198164.png" />
            </div>
            <div className="content">
              <h4>AI</h4>
              <p>Our Assistants &amp; Forms use a branch of AI to help engage with your customers. All you have to do is simply plug &amp; play.</p>
            </div>
          </div>
          <div className="join-us-details-single">
            <div className="img">
              <img width="130" src="https://d365pq86x330zn.cloudfront.net/41524179-664G-46o5-1o5o-9r7o272625o3.png" />
            </div>
            <div className="content">
              <h4>UX Design</h4>
              <p>How you customers interact with your business, brand, website and systems can be make or break. Let our experts lend you a helping hand.</p>
            </div>
          </div>
        </div>
      </div>
      </Fade>
    </div>
  </section>
</>)
};

export default JoinUs;