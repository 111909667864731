import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination, Navigation } from "swiper";

import './banner.css';

const Banner = () => {
  const swiperRef = useRef();

  return (
  <section className="banner-hpac-com-wrapper ">
    <div className="banner-hpac-com">
      <Swiper slidesPerView={1} allowSlideNext={true} ref={swiperRef} preventClicks={true}>
        <SwiperSlide>
          <h2>Step 1: Register for a Free Trial</h2>
          <h3>Outbound</h3>
          <section className="banner-hpac-comm-wrapper">
            <div className="banner-hpac-comm">
              <div className="banner-hpac-comm-image"></div>
              <div className="banner-hpac-comm-sign-in">
                <div className="banner-hpac-comm-sign-in-inner">
                  <img height={100} src="https://d365pq86x330zn.cloudfront.net/N38wN436-598e-4121-9w8w-66w368864e9N.jpg" />
                  <br/><br/>
                  <h2>We just sent you an SMS</h2>
                  <p>To Log in, enter the security code we sent to ******890</p>
                  <p><a href="#">Learn more</a></p>
                  <br/>
                  <div className="form-group">
                    <label>Type your 6-digit security code here:</label>
                    <input type={'text'} placeholder={'Enter code here'} />
                  </div>
                  <br/>
                  <button  onClick={()=>{swiperRef.current.swiper.slideNext();}}>Done</button>
                  <br/><br/>
                  <p><a href="#">Try Again</a></p>
                </div>
              </div>
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide onClick={()=>{swiperRef.current.swiper.slideNext();}}>
          <h2>Step 2: Check your Phone and Respond</h2>
          <h3>&nbsp;</h3>
          <img src="https://d365pq86x330zn.cloudfront.net/6170A516-604W-407D-1707-05065W0AAAAW.png" />
        </SwiperSlide>
        <SwiperSlide>
          <h2>Step 3: Check your Inbound Dashboard</h2>
          <p>To access our Demo account, please enter <strong>parker_automateai@meetparker.io</strong> for both email and password.</p>
          <h3>Inbound</h3>
          <iframe src="https://dev-cms.meetparker.io/login"></iframe>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
  )
};

export default Banner;