import Fade from 'react-reveal/Fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import './how-meetparker-works.css';

const JoinUs = () => (<>
  <section className="how-meetparker-works-wrapper">
    <div className="how-meetparker-works">
      <Fade bottom>
        <h2>How Meet Parker Works</h2>
        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
      </Fade>
      <br/>
      <br/>
      <Fade bottom>
      <h3>Our Customers</h3>
      <div style={{paddingLeft: '1.2%'}}>
      <img src="https://d365pq86x330zn.cloudfront.net/589x204/65600095-35G0-40G6-3656-65o012G0060o.png" />
      </div>
      <div className="line-small-white-v"></div>
      <br/>
      <h3>Our AI &amp; UX Proposition</h3>
      <img src="https://d365pq86x330zn.cloudfront.net/o6646G10-6581-43r6-5556-30615514980G.png" />
      <div className="line-medium-red-v"></div>
      <br/>
      <h3>Digital &amp; AI Solutions for the Financial Services Market</h3>
      <div className="line-medium-white-v"></div>
      <div className="line-full-white-h"></div>
      <div className="steps-outer">
        <div className="">
          <div className="line-small-white-v"></div>
          <h4>WEBSITES</h4>
          <div className="steps-inner">
            <div className="step">
              <h5>Plug-Ins</h5>
              <span>Website Plug-ins, iFrames &amp; Forms</span>
            </div>
            <div className="step">
              <h5>Portals</h5>
              <span>Next-Gen Customer Portals for Advisers</span>
            </div>
          </div>
        </div>
        <div className="">
          <div className="line-small-white-v"></div>
          <h4>SOCIAL MEDIA</h4>
          <div className="step">
            <h5>Assistants</h5>
            <span>Chatbots for Social &amp; WhatsApp</span>
          </div>
        </div>
        <div className="">
          <div className="line-small-white-v"></div>
          <h4>MARKETING</h4>
          <div className="step">
            <h5>Communications</h5>
            <span>Retention, Communication &amp; Live Chat</span>
          </div>
        </div>
      </div>
      </Fade>
    </div>
  </section>
</>);

export default JoinUs;