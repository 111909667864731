import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import './iframed.css';

import Axios from "axios";

const { REACT_APP_SHOP_URL } = process.env

const getProducts = (prodID = "",category = "",type="")=>{
  let filters = "";
  filters = prodID ?  filters + "?id=" + prodID : filters;
  filters = category ?  filters + "?cat=" + category : filters;
  filters = type ?  filters + "?type=" + type : filters;
  const url = `${REACT_APP_SHOP_URL}/api/v1/shop/product${filters}`
  return Axios.get(url);
}

const Iframed = ({ title, url}) => {
  const [searchParams] = useSearchParams();

  const [allApps, setAllApps] = useState([]);
  const [products, setProducts] = useState([]);

  const init = () => {
    getProducts().then(({data})=>{
      setAllApps(()=>data)
    })
  }

  const filterProduct = () => {
    setProducts(()=>allApps.filter(
      product => product.type === 'main'
    ));
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(()=>{
    filterProduct();
  },[allApps])

  return (
  <section className="iframeds-hpac-portal-wrapper">
    <br/><br/>
    <h2>{ searchParams.get("prodId") === "6346db6a44b82bcc1ae0146a" ? "Check out our Best Rates" : title}</h2>
    <div className="iframeds-hpac-portal">
      <div className="iframeds-hpac-portal-inner">
        {
          searchParams.get("prodId") === "6346db6a44b82bcc1ae0146a" ? 
          (
            products.filter((product) => product._id === searchParams.get("prodId")).length ? (
              products.filter((product) => product._id === searchParams.get("prodId"))[0].sample.trim() !== '' ? 
              <>
                <img src="https://d365pq86x330zn.cloudfront.net/01012965-1466-4699-7711-746659M1c656.png"/>
                <iframe src={products.filter((product) => product._id === searchParams.get("prodId"))[0].sample}/>
              </>
              : 
              <>
                <img src="https://d365pq86x330zn.cloudfront.net/01012965-1466-4699-7711-746659M1c656.png"/>
                 <iframe src={products.filter((product) => product._id === searchParams.get("prodId"))[0].image}/>
              </>
            ) 
            : <img src="https://d365pq86x330zn.cloudfront.net/610rc609-c6r6-4316-6665-61c397116Sr2.png"/>
          ) : <img src="https://d365pq86x330zn.cloudfront.net/610rc609-c6r6-4316-6665-61c397116Sr2.png"/>
        }
      </div>
    </div>
    <a href={
      searchParams.get("prodId") === "6346db6a44b82bcc1ae0146a" ? 
      (
        products.filter((product) => product._id === searchParams.get("prodId")).length ? (
          products.filter((product) => product._id === searchParams.get("prodId"))[0].sample.trim() !== '' ? products.filter((product) => product._id === searchParams.get("prodId"))[0].sample : products.filter((product) => product._id === searchParams.get("prodId"))[0].image
        ) 
        : url
      ) : url
    } target="_blank" className="iframeds-button">Get a Quote</a>
    <br/><br/>
    <br/><br/>
    <br/><br/>
    <div className="qoute-ads">
      <div>
        <img src="https://d365pq86x330zn.cloudfront.net/85G86161-54r6-4748-0o4G-r7746GG6G0o5.png" />
        <h3>London Based</h3>
      </div>
      <div>
        <img src="https://d365pq86x330zn.cloudfront.net/5ll5836l-8136-463l-876E-6El3El683845.png" />
        <h3>Bespoke Rates</h3>
      </div>
      <div>
        <img src="https://d365pq86x330zn.cloudfront.net/86s0s110-8s66-4666-00s6-16M066688861.png" />
        <h3>Fee Free</h3>
      </div>
    </div>
    <br/><br/>
    <br/><br/>
  </section>
)};

export default Iframed;