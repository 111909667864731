import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Countdown from 'react-countdown';

import './content.css';

const socket = new WebSocket('wss://dev-socket.meetparker.io');
let connectionId = "";
  
socket.onmessage = (data) => {
  if (JSON.parse(data.data).connectionId) {
    connectionId = JSON.parse(data.data).connectionId;
  }
}

const Content = () => {
  const [ msBeforeBook, setMsBeforeBook ] = useState(0);
  const [ isQuestionSent, setIsQuestionSent ] = useState(false);
  const [ isQ1Open, setIsQ1Open ] = useState(false);
  const [ isQ2Open, setIsQ2Open ] = useState(false);
  const [ isQ3Open, setIsQ3Open ] = useState(false);
  const [ isQ4Open, setIsQ4Open ] = useState(false);
  const [ isQ5Open, setIsQ5Open ] = useState(false);
  const [ isQ6Open, setIsQ6Open ] = useState(false);
  const [ emailReq, setEmailReq ] = useState({
    question_question: "",
    question_name: "",
    question_email: "",
  });

  const submitQuestion = () => {
    if (connectionId.trim() !== "") {
      socket.send(JSON.stringify({
        Entries: [{
          Detail: {
            connectionId,
            type: "send-email",
            secure_email_from: "hello@meetparker.co.uk",
            secure_email:  "hello@meetparker.co.uk", // "hello@meetparker.co.uk"
            secure_email_template: "mp-itmq-lead",
            secure_email_subject: "Meet Parker: Time To Meet Parker Question",
            custom_fields: {
              ...emailReq,
            }
          },
          Time: new Date(),
          Source: `mp-amplify-website`,
          DetailType: `web.to.api`,
          EventBusName: `mp-global-exchange-dev`,
        }],
      }));
      socket.send(JSON.stringify({
        Entries: [{
          Detail: {
            connectionId,
            type: "send-email",
            secure_email_from: "hello@meetparker.co.uk",
            secure_email:  "jonangelo@meetparker.co.uk", // "hello@meetparker.co.uk"
            secure_email_template: "mp-itmq-lead",
            secure_email_subject: "Meet Parker: Time To Meet Parker Question",
            custom_fields: {
              ...emailReq,
            }
          },
          Time: new Date(),
          Source: `mp-amplify-website`,
          DetailType: `web.to.api`,
          EventBusName: `mp-global-exchange-dev`,
        }],
      }));
      setIsQuestionSent(true);
    }
  }

  return (<>
    <section className="time-to-meetparker-content-wrapper">
      <div className="time-to-meetparker-content">
        <h2>Got a question?</h2>
        <p>Parker will reach out to you directly at the end of the session.</p>
        <div className="time-to-meetparker-content-question-form">
          <div className="form-group">
            <label>Write your question:</label>
            <textarea rows={3} onChange={({target: {value}}) => {setEmailReq({
              ...emailReq,
              question_question: value
            })}} required></textarea>
          </div>
          <div className="form-group">
            <label>Name:</label>
            <input value={emailReq.question_name} onChange={({target: {value}}) => {setEmailReq({
              ...emailReq,
              question_name: value
            })}} required />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input value={emailReq.question_email} onChange={({target: {value}}) => {setEmailReq({
              ...emailReq,
              question_email: value
            })}} required />
          </div>
          { isQuestionSent ? 
            <div className="form-group">
              <label><h4>All sorted. Your question is sent.</h4></label>
            </div> : <>
            { (emailReq.question_question.trim() !== "" && 
              emailReq.question_name.trim() !== "" && 
              emailReq.question_email.trim() !== "") ?
            <div className="form-group">
              <label>&nbsp;</label>
              <button className="btn btn-primary-invert" onClick={() => {
                submitQuestion();
              }}>SUBMIT</button>
            </div> :
            <div className="form-group">
              <label>&nbsp;</label>
              <button className="btn btn-primary-invert">SUBMIT</button>
            </div> }
            </> 
          }
        </div>
        <div className={['time-to-meetparker-content-question', (isQ1Open && 'active')].join(' ')} onClick={() => void setIsQ1Open(!isQ1Open)}>
          <div className="splitter-line" />
          <label><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 14L11 7L0 0V14Z" fill="white"/></svg>&nbsp;&nbsp;What is Meet Parker?</label>
          <label className="answer">Here at Meet Parker we build Apps, Websites, Engaging UX and Chatbots for the Mortgage, Insurance and Property Market. From website plugins to aid lead generation, through to complex AI Assistants and Websites. All available as a bespoke design or from our App Marketplace. Fully integrated into your Website and Social Media - here to bring your brand, lead generation and website to life.</label>
        </div>
        <div className={['time-to-meetparker-content-question', (isQ2Open && 'active')].join(' ')} onClick={() => void setIsQ2Open(!isQ2Open)}>
          <div className="splitter-line" />
          <label><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 14L11 7L0 0V14Z" fill="white"/></svg>&nbsp;&nbsp;How many developers do you have?</label>
          <label className="answer">We have a team of 15 Developers and Support Staff.</label>
        </div>
        <div className={['time-to-meetparker-content-question', (isQ3Open && 'active')].join(' ')} onClick={() => void setIsQ3Open(!isQ3Open)}>
          <div className="splitter-line" />
          <label><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 14L11 7L0 0V14Z" fill="white"/></svg>&nbsp;&nbsp;What’s your email address?</label>
          <label className="answer"><a href="mailto:hello@meetparker.co.uk">hello@meetparker.co.uk</a></label>
        </div>
        <div className={['time-to-meetparker-content-question', (isQ4Open && 'active')].join(' ')} onClick={() => void setIsQ4Open(!isQ4Open)}>
          <div className="splitter-line" />
          <label><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 14L11 7L0 0V14Z" fill="white"/></svg>&nbsp;&nbsp;How long have you been running for?</label>
          <label className="answer">2 years</label>
        </div>
        <div className={['time-to-meetparker-content-question', (isQ5Open && 'active')].join(' ')} onClick={() => void setIsQ5Open(!isQ5Open)}>
          <div className="splitter-line" />
          <label><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 14L11 7L0 0V14Z" fill="white"/></svg>&nbsp;&nbsp;How much does it cost?</label>
          <label className="answer">You can visit our pricing page on this <Link to="/pricing">link</Link></label>
        </div>
        <div className={['time-to-meetparker-content-question', (isQ6Open && 'active')].join(' ')} onClick={() => void setIsQ6Open(!isQ6Open)}>
          <div className="splitter-line" />
          <label><svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 14L11 7L0 0V14Z" fill="white"/></svg>&nbsp;&nbsp;Can I book a demo?</label>
          <label className="answer">Click <a href="https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b">here</a> to book a demo with a member of the team.</label>
          <div className="splitter-line" />
        </div>
      </div>
    </section>
  </>);
};

export default Content;