import { Link } from 'react-router-dom';
import Header from '../../components/header/header';
import CommunicateBanner from '../../components/communicate-banner/communicate-banner';
import FeaturesGrid from '../../components/features-grid/features-grid';
import CallToActions from '../../components/call-to-actions/call-to-actions';
import PricingOptions from '../../components/pricing-options/pricing-options';
import GameChanging from '../../components/game-changing/game-changing';
import WantKnowMore from '../../components/want-know-more/want-know-more';
import MultiAward from '../../components/multi-award/multi-award';
import Footer from '../../components/footer/footer';

import './communicate-inbound.scss'

const bannerContent = [
  {
    type: 'element',
    element: <><img src={'https://d365pq86x330zn.cloudfront.net/N168N478-0710-4114-080N-666666160610.png'} /></>,
  },
  {
    type: 'details',
    title: 'Communicate Inbound',
    desc: 'Interactive Pages where AI completes the sale, feeding data to your CRM.',
    buttons: [
      {
        type: 'secondary',
        url: 'https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b',
        text: 'Book a demo',
      },
    ]
  },
];
const featuresGridData = {
  title: 'Turn Digital Traffic into Revenue',
  features: [
    'Calendar Integration',
    '24/7',
    'Real Time Rate Update',
    'Branded to your Business',
    'Telephone Integrated',
    'Customised',
  ],
};
const callToActions = [
  {
    image: 'https://d365pq86x330zn.cloudfront.net/1l066414-5641-4171-7a77-40a665771474.png',
    details: {
      title: 'Turn every visitor into Live Chat',
      desc: 'With Communicate Inbound, you will never miss another lead. Parker offers a seamless digital experience for clients visiting your site, leading to more leads and greater conversion rates.',
    }
  },
  {
    image: 'https://d365pq86x330zn.cloudfront.net/910vi179-0994-4141-0011-v5511775v841.png',
    details: {
      title: 'Onboard more clients with Parker',
      desc: 'With Communicate Inbound, you will never miss another lead. Parker offers a seamless digital experience for clients visiting your site, leading to more leads and greater conversion rates.',
    }
  }
];
const pricingOptionsData = {
  title: 'Two Pricing Options',
  desc: 'Choose the model which suits you best',
  type: 'communicate-inbound',
  options: [
    {
      title: 'Standard',
      desc: 'Ready to go out of the box. No Customisation.',
      perMonth: '150',
      buyNowLink: '#take-me-to',
      note: 'for Non Branded',
      color: '#7DB49C',
    },
    {
      title: 'Premium',
      desc: 'Choose a Communicate Inbound Design and Brand to your Business.',
      perMonth: '250',
      buyNowLink: '#take-me-to',
      note: 'for Branded',
      color: '#0C4584',
    },
    // {
    //   title: 'Premium Plus',
    //   desc: 'Choose up to 3 Communicate Inbound Designs & Brand each to your business',
    //   perMonth: '300',
    //   buyNowLink: '#take-me-to',
    //   note: 'for Branded',
    //   color: '#E0AA30',
    // },
  ]
};

const CommunicateInbound = () => (<main>
  <Header/>
  <CommunicateBanner contents={bannerContent} />
  <section className="take-me-to" id="take-me-to">
    <div className="take-me-to-inner">
      <br/><br/>
      <h2>Take me to:</h2>
      <div className="links">
        <Link to={'/communicate-inbound/mortgage-onboarding'}>Mortgage</Link>
        <Link to={'/communicate-inbound/insurance'}>Insurance</Link>
        <Link to={'/'} className="disable">Wealth - Coming Soon</Link>
      </div>
      <img src="https://d365pq86x330zn.cloudfront.net/r0r0rG61-0o5r-4505-2742-71511o90G567.png" />
    </div>
  </section>
  <FeaturesGrid featuresGridData={featuresGridData} />
  <CallToActions callToActions={callToActions} />
  {/* <PricingOptions pricingOptionsData={pricingOptionsData} /> */}
  <GameChanging />
  <WantKnowMore />
  <MultiAward />
  <Footer/>
</main>);

export default CommunicateInbound;