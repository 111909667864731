import { useEffect } from 'react';import './loading.css';

const Loading = () => {
  useEffect(() => {
    window.location.href = 'https://www.twenty7tec.com/communicate';
  }, []);
  
  return (
    <div className="loader-wrapper">
      <div id="loader">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="loading"></div>
        </div>
    </div>
  )
};

export default Loading;