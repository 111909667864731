import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import './banner.css';

import Axios from "axios";

const { REACT_APP_SHOP_URL } = process.env

const getProducts = (prodID = "",category = "",type="")=>{
  let filters = "";
  filters = prodID ?  filters + "?id=" + prodID : filters;
  filters = category ?  filters + "?cat=" + category : filters;
  filters = type ?  filters + "?type=" + type : filters;
  const url = `${REACT_APP_SHOP_URL}/api/v1/shop/product${filters}`
  return Axios.get(url);
}

const Banner = ({ type }) => {
  const [searchParams] = useSearchParams();

  const [allApps, setAllApps] = useState([]);
  const [products, setProducts] = useState([]);

  const init = () => {
    getProducts().then(({data})=>{
      setAllApps(()=>data)
    })
  }

  const filterProduct = () => {
    setProducts(()=>allApps.filter(
      product => product.type === 'main'
    ));
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(()=>{
    filterProduct();
  },[allApps])

  return (
  <section className="banner-hpac-wrapper hga-outer">
    <div className="house-glass-animation-wrapper">
      <div className="house-glass-animation">
        <img className="hga-main-image" src="https://d365pq86x330zn.cloudfront.net/6G5666o4-G676-4665-7147-57517rr6671G.png" />
        <img className="hga-pie-chart" src="https://d365pq86x330zn.cloudfront.net/17r91452-2966-49o2-1266-6969oo917161.png" />
        <img className="hga-bar-chart" src="https://d365pq86x330zn.cloudfront.net/7096r6r9-7ro7-4o3r-9rGr-978707367617.png" />
        <img className="hga-sms-image" src="https://d365pq86x330zn.cloudfront.net/36388r67-93G7-4r17-4099-31661o693713.png" />
        <img className="hga-2cn-image" src="https://d365pq86x330zn.cloudfront.net/4oG1o991-9966-46r9-906G-0o86047r8944.png" />
        <img className="hga-5cn-image" src="https://d365pq86x330zn.cloudfront.net/616Gr66G-1667-4or6-5757-17171677771o.png" />
      </div>
    </div>
    <div className="house-glass-animation-wrapper right">
      <div className="house-glass-animation">
        <img className="hga-main-image" src="https://d365pq86x330zn.cloudfront.net/6G5666o4-G676-4665-7147-57517rr6671G.png" />
        <img className="hga-pie-chart" src="https://d365pq86x330zn.cloudfront.net/17r91452-2966-49o2-1266-6969oo917161.png" />
        <img className="hga-bar-chart" src="https://d365pq86x330zn.cloudfront.net/7096r6r9-7ro7-4o3r-9rGr-978707367617.png" />
        <img className="hga-sms-image" src="https://d365pq86x330zn.cloudfront.net/36388r67-93G7-4r17-4099-31661o693713.png" />
        <img className="hga-2cn-image" src="https://d365pq86x330zn.cloudfront.net/4oG1o991-9966-46r9-906G-0o86047r8944.png" />
        <img className="hga-5cn-image" src="https://d365pq86x330zn.cloudfront.net/616Gr66G-1667-4or6-5757-17171677771o.png" />
      </div>
    </div>
    <div className="banner-hpac">
      <Fade big>
        <h1>Your {
          products.length ?
          products.filter(
            (product) => 
            product._id === searchParams.get("prodId") &&
            product.category.includes("insurance")
          ).length
          ? 
          <>
            { searchParams.get("prodId") == '6346854a44b82bcc1ae01460' ? 'Insurance' : 'Mortage' }
          </>
          : 'London'
          : null
        } Broker</h1>
        <p>Mortgage, Wealth Management & Insurance Advice</p>
        <div className="single-content">
          <a href={"https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b"} className="pac-btn">Book an Appointment</a>
          { type === 'assistants' ? 
            <a href={"https://www.whatsapp.com/"} target="_blank" className="pac-btn black">
              Let’s Chat Now
              <img src="https://d365pq86x330zn.cloudfront.net/26446641-676c-4066-22c9-c67662879026.png" />
            </a>
          :
            <a href={
              searchParams.get("prodId") ? 
              (
                products.filter((product) => product._id === searchParams.get("prodId")).length ? (
                  products.filter((product) => product._id === searchParams.get("prodId"))[0].sample.trim() !== '' ? products.filter((product) => product._id === searchParams.get("prodId"))[0].sample : products.filter((product) => product._id === searchParams.get("prodId"))[0].image
                ) 
                : "https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b"
              ) : "https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b"
            } target="_blank" className="pac-btn black">Get Started Online</a>
          }
        </div>
      </Fade>
      <div className="house-glass-animation mobile">
        <img className="hga-main-image" src="https://d365pq86x330zn.cloudfront.net/6G5666o4-G676-4665-7147-57517rr6671G.png" />
        <img className="hga-pie-chart" src="https://d365pq86x330zn.cloudfront.net/17r91452-2966-49o2-1266-6969oo917161.png" />
        <img className="hga-bar-chart" src="https://d365pq86x330zn.cloudfront.net/7096r6r9-7ro7-4o3r-9rGr-978707367617.png" />
        <img className="hga-sms-image" src="https://d365pq86x330zn.cloudfront.net/36388r67-93G7-4r17-4099-31661o693713.png" />
        <img className="hga-2cn-image" src="https://d365pq86x330zn.cloudfront.net/4oG1o991-9966-46r9-906G-0o86047r8944.png" />
        <img className="hga-5cn-image" src="https://d365pq86x330zn.cloudfront.net/616Gr66G-1667-4or6-5757-17171677771o.png" />
      </div>
    </div>
  </section>
)};

export default Banner;