import Header from '../../../components/parker-in-action/header/header';
import Footer from '../../../components/parker-in-action/footer/footer';
import JoinUs from '../../../components/parker-in-action/join-us/join-us';
import Customer from '../../../components/parker-in-action/customer/customer';
import HowMeetparkerWorks from '../../../components/parker-in-action/how-meetparker-works/how-meetparker-works';
import UnfullImage from '../../../components/parker-in-action/unfull-image/unfull-image';
import Banner from './banner/banner';
import PIABanner from './../../../components/parker-in-action/banner/banner';
import Iframed from '../../../components/parker-in-action/iframed/iframed';
import CaseStudies from '../../../components/parker-in-action/case-studies/case-studies';

const Assistant = () => (
  <main>
    <Header title={'Assistants'}/>
    {/* <Banner /> */}
    <PIABanner type={'assistants'} />
    <Customer />
    <Iframed title={'Try our Life Insurance Calculator'} url={'https://mortgage-chat.meetparker.io/client?apiKey=94d0c4c0-91a1-49e7-8c09-2b1ad02fd68e'} />
    {/* <HowMeetparkerWorks/> */}
    <CaseStudies />
    <JoinUs />
    <Footer />
  </main>
);

export default Assistant;