import React, { useState } from 'react';

import './content.css';

const socket = new WebSocket('wss://dev-socket.meetparker.io');
let connectionId = "";
  
socket.onmessage = (data) => {
  if (JSON.parse(data.data).connectionId) {
    connectionId = JSON.parse(data.data).connectionId;
  }
}

const Content = () => {
  const [ isEmailSentTFC, setIsEmailSentTFC ] = useState(false);
  const [ emailReqTFC, setEmailReqTFC ] = useState({
    tfc_name: "",
    tfc_email: ""
  });
  const submitEmailTFC = () => {
    if (connectionId.trim() !== "") {
      socket.send(JSON.stringify({
        Entries: [{
          Detail: {
            connectionId,
            type: "send-email",
            secure_email_from: "hello@meetparker.co.uk",
            secure_email:  `${emailReqTFC.tfc_email}`,
            secure_email_template: "mp-tfc",
            secure_email_subject: "Meet Parker: Thanks for coming",
            custom_fields: {
              ...emailReqTFC,
            }
          },
          Time: new Date(),
          Source: `mp-amplify-website`,
          DetailType: `web.to.api`,
          EventBusName: `mp-global-exchange-dev`,
        }],
      }));
      setIsEmailSentTFC(true);
    }
  }

  return (<>
    <section className="time-to-meetparker-content-wrapper">
      <div className="time-to-meetparker-content class">
        <h2>Email</h2>
        <p>Send email.</p>
        <div className="time-to-meetparker-content-question-form">
          <div className="form-group">
            <label>Name:</label>
            <input value={emailReqTFC.tfc_name} onChange={({target: {value}}) => {setEmailReqTFC({
              ...emailReqTFC,
              tfc_name: value
            })}} required />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" name="email" value={emailReqTFC.tfc_email} onChange={({target: {value}}) => {setEmailReqTFC({
              ...emailReqTFC,
              tfc_email: value
            })}} required />
          </div>
          { isEmailSentTFC ? 
            <div className="form-group">
              <label>
                <h4>All sorted. The email is sent.</h4>
                <button className="btn btn-primary-invert" onClick={() => {
                  setIsEmailSentTFC(false);
                }}>SEND ANOTHER Thanks for coming email</button>
              </label>
            </div> : <>
            { (emailReqTFC.tfc_name.trim() !== "" && 
              emailReqTFC.tfc_email.trim() !== "") ?
            <div className="form-group">
              <label>&nbsp;</label>
              <button className="btn btn-primary-invert" onClick={() => {
                submitEmailTFC();
              }}>SEND Thanks for coming email</button>
            </div> :
            <div className="form-group">
              <label>&nbsp;</label>
              <button className="btn btn-primary-invert">SEND Thanks for coming email</button>
            </div> }
            </> 
          }
        </div>
      </div>
    </section>
  </>);
};

export default Content;