import Header from './../../../components/parker-in-action/header/header';
import Footer from './../../../components/parker-in-action/footer/footer';
import JoinUs from './../../../components/parker-in-action/join-us/join-us';
import Customer from './../../../components/parker-in-action/customer/customer';
import HowMeetparkerWorks from './../../../components/parker-in-action/how-meetparker-works/how-meetparker-works';
import Contents from './contents/contents';
import Banner from './../../../components/parker-in-action/banner/banner';

const Home = () => (
  <main>
    <Header />
    <Banner />
    <Customer />
    <HowMeetparkerWorks/>
    <Contents />
    <JoinUs />
    <Footer />
  </main>
);

export default Home;