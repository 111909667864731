import React, { useState } from 'react';
import { Link } from "react-router-dom";

import './header.css';

const Header = ({ title, gso }) => {
  const [mobileToggle, setMobileToggle] = useState(false);

  return (
    <>
      { !title ?
        <header className={'header parker-in-action-p ' + (mobileToggle ? 'open' : '')}>
          <nav className="nav">
            <ul className="left-navbar">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
            </ul>
            <div className="logo-wrapper">
              <div className="toggle-open" onClick={() => {setMobileToggle(true)}}>
                <svg fill="#262626" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="28px" height="28px"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>
              </div>
              <div className="toggle-close" onClick={() => {setMobileToggle(false)}}>
                <svg fill="#262626" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="28px" height="28px"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/></svg>
              </div>
              <Link to="/">
                <img className="logo" alt="Meet Parker Logo" src="https://d365pq86x330zn.cloudfront.net/66916948-61rr-4916-1668-8r1196911916.png" />
              </Link>
              <svg className="space-filler" fill="transparent" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="28px" height="28px"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>
            </div>
            <ul className="right-navbar">
              <li><a href="https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b"><button className="btn btn-success btn-block">Try it For Free</button></a></li>
            </ul>
          </nav>
        </header> :
        <header className={'header parker-in-action-p titled ' + (mobileToggle ? 'open' : '')}>
          <nav className="nav">
            <ul className="left-navbar">
              <li><img className="logo-intro" height={55} alt="Meet Parker Logo" src="https://d365pq86x330zn.cloudfront.net/66916948-61rr-4916-1668-8r1196911916.png" /></li>
              <li><h2>{title}</h2></li>
            </ul>
            <div className="logo-wrapper">
              <div className="toggle-open" onClick={() => {setMobileToggle(true)}}>
                <svg fill="#262626" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="28px" height="28px"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>
              </div>
              <div className="toggle-close" onClick={() => {setMobileToggle(false)}}>
                <svg fill="#262626" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="28px" height="28px"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/></svg>
              </div>
              <Link to="/">
                <img height={50} className="logo" alt="Meet Parker Logo" src="https://d365pq86x330zn.cloudfront.net/66916948-61rr-4916-1668-8r1196911916.png" />
              </Link>
              <svg className="space-filler" fill="transparent" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="28px" height="28px"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>
            </div>
            <ul className="right-navbar">
              <li><a href={"https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b"} className="pac-btn">Book a Demo</a><a href={gso ? gso : "https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b"} className="pac-btn black">Get Started Online</a></li>
            </ul>
          </nav>
        </header>
      }
      <div className="header-placeholder"></div>
    </>
  );
};

export default Header;