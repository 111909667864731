import React, { useState, useEffect } from 'react';

import Timer from './../../../components/timer/timer';

import './content.css';

const socket = new WebSocket('wss://dev-socket.meetparker.io');
let connectionId = "";
  
socket.onmessage = (data) => {
  if (JSON.parse(data.data).connectionId) {
    connectionId = JSON.parse(data.data).connectionId;
  }
}

const Content = () => {
  const [ msBeforeBook, setMsBeforeBook ] = useState(0);
  const [ isBooked, setIsBooked ] = useState(false);
  const [ emailReq, setEmailReq ] = useState({
    book_firstName: "",
    book_lastName: "",
    book_email: "",
    book_company: "",
    book_datetime: "",
  });

  const submitBookRegistration = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    if (connectionId.trim() !== "") {
      socket.send(JSON.stringify({
        Entries: [{
          Detail: {
            connectionId,
            type: "send-email",
            secure_email_from: "hello@meetparker.co.uk",
            secure_email:  "hello@meetparker.co.uk", // "hello@meetparker.co.uk"
            secure_email_template: "mp-itm-lead",
            secure_email_subject: "Meet Parker: It's Time To Meet Parker Entry",
            custom_fields: {
              ...emailReq,
              book_datetime: `Friday 8th July at 1100`
            }
          },
          Time: new Date(),
          Source: `mp-amplify-website`,
          DetailType: `web.to.api`,
          EventBusName: `mp-global-exchange-dev`,
        }],
      }));
      socket.send(JSON.stringify({
        Entries: [{
          Detail: {
            connectionId,
            type: "mp-its-time-to-parker",
            secure_email_from: "hello@meetparker.co.uk",
            secure_email:  `${emailReq.book_email}`, // "hello@meetparker.co.uk"
            secure_email_template: "mp-itm",
            secure_email_subject: "Meet Parker: Registration Confirmed",
            custom_fields: {
              ...emailReq,
              book_datetime: `Friday 8th July at 1100`
            }
          },
          Time: new Date(),
          Source: `mp-amplify-website`,
          DetailType: `web.to.api`,
          EventBusName: `mp-global-exchange-dev`,
        }],
      }));
      setIsBooked(true);
    }
  }

  const specialCSS = `.its-time-to-meet-parker-nav {display: none!important;}`;

  return (<>
    <style>{specialCSS}</style>
    <section className="its-time-to-meetparker-form-wrapper">
      <div className="its-time-to-meetparker-form">
        <div className="its-time-to-meetparker-form-text">
          <h1>It's Time to <label>Meet Parker</label>.</h1>
          <p>A 15 Minute Livestream Event showcasing the Latest Application of AI in the Mortgage, Insurance &amp; Property Industry.</p>
          <iframe className="its-time-to-meetparker-form-vid" src="https://www.youtube.com/embed/IwJktHqppuY" title="MP YT" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>
        <div className="its-time-to-meetparker-form-fields">
          <Timer eventDateTime="2022-07-08T11:00:00+0100" eventLabel="BEFORE LIVESTREAM" />
          <h3>Register below <br/><label>Friday 8th July - 11am</label></h3>
          <br/>
          <form className="its-time-to-meetparker-form-fields-card">
            <div className="form-row">
              <div className="form-col">
                <div className="form-group">
                  <label>First Name</label>
                  <input value={emailReq.book_firstName} onChange={({target: {value}}) => {setEmailReq({
                    ...emailReq,
                    book_firstName: value
                  })}} placeholder="First Name" required />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <div className="form-group">
                  <label>Last Name</label>
                  <input value={emailReq.book_lastName} onChange={({target: {value}}) => {setEmailReq({
                    ...emailReq,
                    book_lastName: value
                  })}} placeholder="Last Name" required />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <div className="form-group">
                  <label>Email Address</label>
                  <input value={emailReq.book_email} onChange={({target: {value}}) => {setEmailReq({
                    ...emailReq,
                    book_email: value
                  })}} placeholder="email.com" required />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <div className="form-group">
                  <label>Company</label>
                  <input value={emailReq.book_company} onChange={({target: {value}}) => {setEmailReq({
                    ...emailReq,
                    book_company: value
                  })}} placeholder="Company" required />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                { isBooked ? 
                <div className="form-group">
                  <label><h4>All sorted. You will receive an email confirmation shortly.</h4></label>
                </div> : <>
                { (emailReq.book_firstName.trim() !== "" && 
                  emailReq.book_lastName.trim() !== "" && 
                  emailReq.book_email.trim() !== "" && 
                  emailReq.book_company.trim() !== "") ?
                <div className="form-group">
                  <label>&nbsp;</label>
                  <input type="submit" value="register" onClick={(e) => {
                    e.preventDefault();
                    submitBookRegistration();
                  }} />
                </div> :
                <div className="form-group">
                  <label>&nbsp;</label>
                  <input type="submit" value="register" />
                </div> }
                </> }
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </>);
};

export default Content;