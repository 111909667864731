import Header from '../../../components/parker-in-action/header/header';

const Plugins = () => (
  <main style={{display: 'flex', flexDirection: 'column'}}>
    <div style={{flex: '0 1 auto'}}>
      <Header title={'Plugins'} gso={'/parker-in-action/plugin-go'}/>
    </div>
    <div style={{flex: '0 1 auto'}}>
      <iframe style={{border: 'none', outline: 'none', width: '100vw', height: 'calc(100vh - 82px)'}} src={'https://test-mortgage-chat.meetparker.io/client?apiKey=2e8fb982-336b-45c3-b81e-c184b052d62a'}></iframe>
    </div>
  </main>
);

export default Plugins;