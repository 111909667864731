import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// COMPONENT
import FullPageIframe from './components/full-page-iframe/full-page-iframe';
import MeetparkerBot from './components/meetparker-bot/meetparker-bot';

// PAGES
import TermsAndConditions from './pages/terms-and-conditions/terms-and-conditions';
import PrivacyPolicy from './pages/privacy-policy/privacy-policy';
import CookiePolicy from './pages/cookie-policy/cookie-policy';
import NotFound from './pages/not-found/not-found';
import Loading from './pages/loading/loading';
import BookADemo from './pages/book-a-demo/book-a-demo';
import AICentre from './pages/ai-centre/ai-centre';
import About from './pages/about/about';
import ItsTimetoMeetParker from './pages/its-time-to-meet-parker/its-time-to-meet-parker';
import TimetoMeetParker from './pages/time-to-meet-parker/time-to-meet-parker';
import EmailSender from './pages/email-sender/email-sender';
import BusinessToClients from './pages/business-to-clients/business-to-clients';
import BusinessToBusiness from './pages/business-to-business/business-to-business';
import Pricing from './pages/pricing/pricing';
import Product from './pages/product/product';
import Post from './pages/post/post';
import Home from './pages/home/home';
import Confirm from './pages/checkout/confirm';
import ThankYou from './pages/thank-you/thank-you';
import AppStore from './pages/app-store/app-store';
import Search from './pages/search/search';
import Assistants from './pages/assistants/assistants';
import Portals from './pages/portals/portals';
import Plugins from './pages/plugins/plugins';
import PluginsMortgageInsurance from './pages/plugins/mortgage-insurance/mortgage-insurance';
import PluginsProperty from './pages/plugins/property/property';
import PluginsSupport from './pages/plugins/support/support';
import PluginsCalculators from './pages/plugins/calculators/calculators';

import Communicate from './pages/communicate/communicate';
import CommunicateOutbound from './pages/communicate-outbound/communicate-outbound';
import CommunicateInbound from './pages/communicate-inbound/communicate-inbound';
import CommunicateInboundMortgageOnboarding from './pages/communicate-inbound/mortgage-onboarding/mortgage-onboarding';
import CommunicateInboundMortgageCalculator from './pages/communicate-inbound/mortgage-calculator/mortgage-calculator';
import CommunicateInboundInsurance from './pages/communicate-inbound/insurance/insurance';

import Twenty7tec from './pages/twenty7tec/twenty7tec';

import GDPR from './pages/gdpr/gdpr';
import JoinTeamParker from './pages/join-team-parker/join-team-parker';

import PACHome from './pages/parker-in-action/home/home';
import PACCommunicate from './pages/parker-in-action/communicate/communicate';
import PACPlugins from './pages/parker-in-action/plugins/plugins';
import PACPluginsGSO from './pages/parker-in-action/plugins/plugins-gso';
import PACPortals from './pages/parker-in-action/portals/portals';
import PACAssistants from './pages/parker-in-action/assistants/assistants';

// POSTS
import HowMuchShouldANewWebsiteCost from './posts/how-much-should-a-new-website-cost/how-much-should-a-new-website-cost';
import BuyingLeadsIsItWorthIt from './posts/buying-leads-is-it-worth-it/buying-leads-is-it-worth-it';
import WebsiteFormsOrChatbotsItsAHeavyweightLineUp from './posts/website-forms-or-chatbots-its-a-heavyweight-line-up/website-forms-or-chatbots-its-a-heavyweight-line-up';

import ScrollToTop from './hooks/ScrollToTop';

import './App.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/">
              <Route index element={<Loading />} />
              {/* <Route path="thank-you" element={<ThankYou />} />
              <Route path="terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="cookie-policy" element={<CookiePolicy />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="book-a-demo" element={<BookADemo />} /> */}

              {/* <Route path="its-time-to-meet-parker" element={<Navigate to="/book-a-demo" replace />} />
              <Route path="time-to-meet-parker" element={<Navigate to="/book-a-demo" replace />} /> */}

              {/* <Route path="email-sender-FF385C" element={<EmailSender />} /> */}

              {/* <Route path="business-to-clients" element={<BusinessToClients />} />
              <Route path="business-to-business" element={<BusinessToBusiness />} /> */}

              {/* <Route path="pricing" element={<Pricing />} />
              <Route path="ai-centre" element={<AICentre />} />About
              <Route path="about-us" element={<About />} />
              <Route path="nationwide-demo" element={<FullPageIframe link={'https://test-mortgage-chat.meetparker.io/client?apiKey=4dca8a8e-be1c-45e9-8d32-1cf1e569df49'} />} />
              <Route path="meetparkeriframebot" element={<MeetparkerBot />} />
              <Route path="b2bmwebsitewidget" element={<MeetparkerBot />} />
              <Route path="b2cmwebsitewidget" element={<MeetparkerBot />} />
              <Route path="testfairplugin" element={<MeetparkerBot />} />
              <Route path="Confirm" element={<Confirm />} /> */}

              {/* <Route path="app-store" element={<AppStore />} />
              <Route path="search" element={<Search />} /> */}
              {/* <Route path="gdpr" element={<GDPR />} /> */}

              {/* <Route path="join-team-parker" element={<JoinTeamParker />} />

              <Route path="assistants" element={<Assistants />} />
              <Route path="portals" element={<Portals />} />

              <Route path="twenty7tec" element={<Twenty7tec />} />

              <Route path="communicate" element={<Communicate />} />
              <Route path="communicate-outbound" element={<CommunicateOutbound />} /> */}

              {/* <Route path="communicate-inbound">
                <Route index element={<CommunicateInbound/>} />
                <Route path="mortgage-onboarding" element={<CommunicateInboundMortgageOnboarding/>} />
                <Route path="mortgage-calculator" element={<CommunicateInboundMortgageCalculator/>} />
                <Route path="insurance" element={<CommunicateInboundInsurance/>} />

                <Route path="mortgage-and-insurance" element={<PluginsMortgageInsurance/>} />
                <Route path="property" element={<PluginsProperty/>} />
                <Route path="support" element={<PluginsSupport/>} />
                <Route path="calculators" element={<PluginsCalculators/>} />
              </Route> */}

              
              <Route path="parker-in-action">
                <Route index element={<PACHome/>} />
                <Route path="communicate" element={<PACCommunicate/>} />
                
                <Route path="plugins" element={<PACPlugins/>} />
                <Route path="plugin-go" element={<PACPluginsGSO/>} />
                <Route path="portals" element={<PACPortals/>} />
                <Route path="assistants" element={<PACAssistants/>} />
              </Route>

              {/* <Route path="post">
                <Route index element={<Post><HowMuchShouldANewWebsiteCost /></Post>} />
                <Route path="how-much-should-a-new-website-cost" element={<Post><HowMuchShouldANewWebsiteCost /></Post>} />
                <Route path="buying-leads-is-it-worth-it" element={<Post><BuyingLeadsIsItWorthIt /></Post>} />
                <Route path="website-forms-or-chatbots-its-a-heavyweight-line-up" element={<Post><WebsiteFormsOrChatbotsItsAHeavyweightLineUp /></Post>} />
              </Route>
              <Route path="product/:productId" element={<Product />} /> */}
              <Route path="*" element={<Loading />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
