import Header from '../../../components/header/header';
import CommunicateBanner from '../../../components/communicate-banner/communicate-banner';
import Store from '../../../components/store/store';
import FeaturesList from '../../../components/features-list/features-list';
import PricingOptions from '../../../components/pricing-options/pricing-options';
import GameChanging from '../../../components/game-changing/game-changing';
import WantKnowMore from '../../../components/want-know-more/want-know-more';
import MultiAward from '../../../components/multi-award/multi-award';
import Footer from '../../../components/footer/footer';

import './insurance.scss'

const bannerContent = [
  {
    type: 'element',
    element: <><img src={'https://d365pq86x330zn.cloudfront.net/56280791-6178-495L-9872-1518207v52L0.png'} /></>,
  },
  {
    type: 'details',
    title: 'Insurance',
    desc: 'Interactive Pages where AI completes the sale, feeding data to your CRM.',
    buttons: [
      {
        type: 'secondary',
        url: 'https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b',
        text: 'Book a demo',
      },
    ]
  },
]
const featuresListData = {
  title: 'How does it work?',
  image: 'https://d365pq86x330zn.cloudfront.net/33616316-6113-4316-3614-156361666541.png',
  features: [
    {
      image: 'https://d365pq86x330zn.cloudfront.net/544VV3Vc-2635-429e-3443-5Vc2413e4459.png',
      text: 'Choose from a Branded Template',
    },
    {
      image: 'https://d365pq86x330zn.cloudfront.net/45411465-6151-4o56-18G1-515411561414.png',
      text: 'Automate Messages through Facebook, WhatsApp, Twitter, Instagram, TikTok and More',
    },
    {
      image: 'https://d365pq86x330zn.cloudfront.net/1566r161-242G-44o4-6746-414r6o22r444.png',
      text: 'Let Clients know their Rate is coming to an end, or a policy needs renewing',
    },
    {
      image: 'https://d365pq86x330zn.cloudfront.net/444144o3-425o-4234-9626-525224482o14.png',
      text: 'Communicate responds to queries in real time',
    },
    {
      image: 'https://d365pq86x330zn.cloudfront.net/V1145V16-5568-4566-8c48-06c4566V56e8.png',
      text: 'Pass warm leads straight to your Live Chat Team',
    },
    {
      image: 'https://d365pq86x330zn.cloudfront.net/54r66264-G4G2-44oo-4441-o6044027444o.png',
      text: 'No more batch and blast emails, reach clients on the social channel they use most',
    },
    {
      image: 'https://d365pq86x330zn.cloudfront.net/643o636o-or61-4666-6o3G-4o96o664o663.png',
      text: 'Fully integrated into CRM',
    },
  ],
};
const pricingOptionsData = {
  title: 'Two Pricing Options',
  desc: 'Choose the model which suits you best',
  type: 'communicate-inbound',
  options: [
    {
      title: 'Standard',
      desc: 'Ready to go out of the box. No Customisation.',
      perMonth: '150',
      buyNowLink: '#store-insurance',
      note: 'for Non Branded',
      color: '#7DB49C',
    },
    {
      title: 'Premium',
      desc: 'Choose a Communicate Inbound Design and Brand to your Business.',
      perMonth: '250',
      buyNowLink: '#store-insurance',
      note: 'for Branded',
      color: '#0C4584',
    },
    // {
    //   title: 'Premium Plus',
    //   desc: 'Choose up to 3 Communicate Inbound Designs & Brand each to your business',
    //   perMonth: '300',
    //   buyNowLink: '#store-insurance',
    //   note: 'for Branded',
    //   color: '#E0AA30',
    // },
  ]
};

const Mortgage = () => (<main>
  <Header/>
  <CommunicateBanner contents={bannerContent} />
  <Store type={'insurance'} />
  <FeaturesList featuresLisData={featuresListData} />
  {/* <PricingOptions pricingOptionsData={pricingOptionsData} /> */}
  <GameChanging />
  <WantKnowMore />
  <MultiAward />
  <Footer/>
</main>);

export default Mortgage;