import { useRef, useState } from "react";
import Axios from 'axios';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

import './join-team-parker.css';

const socket = new WebSocket('wss://dev-socket.meetparker.io');
let connectionId = "";
  
socket.onmessage = (data) => {
  if (JSON.parse(data.data).connectionId) {
    connectionId = JSON.parse(data.data).connectionId;
  }
}

const JoinTeamParker = () => {
  const fileinputEl = useRef(null);
  const [fileData, setFileData] = useState(null)
  const [showSuccess, setShowSuccess] = useState();
  const [ emailReq, setEmailReq ] = useState({
    book_firstName: "",
    book_lastName: "",
    book_email: "",
    book_number: "",
    book_cv: "",
  });

  const sendJoinerForm = () => {
    if (connectionId.trim() !== "") {
      if (fileData?.file) {
        const formData = new FormData();
        formData.append('file', fileData?.file);
        Axios.post('https://api.meetparker.io/comms/uploadFile',formData)
        .then(({ data }) => {
          socket.send(JSON.stringify({
            Entries: [{
              Detail: {
                connectionId,
                type: "send-email",
                secure_email_from: "hello@meetparker.co.uk",
                secure_email:  "hello@meetparker.co.uk",
                secure_email_template: "mp_join_team_parker",
                secure_email_subject: "Meet Parker: Join Team Parker Entry",
                custom_fields: {
                  ...emailReq,
                  book_cv: `${data.data.Location}`
                }
              },
              Time: new Date(),
              Source: `mp-amplify-website`,
              DetailType: `web.to.api`,
              EventBusName: `mp-global-exchange-dev`,
            }],
          }));
          setShowSuccess(true);
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }
  }

  return (
  <main>
    <Header/>
    <section className="join-team-parker-wrapper">
      <div className="join-team-parker">
        <h2>Join Team Parker</h2>
        <div className="join-team-parker-inner">
          <h3>Personal Information</h3>
          <div className="join-team-parker-forms">
            <form className="join-team-parker-form" onSubmit={(e)=>{
              sendJoinerForm();
              e.preventDefault();
            }}>
              <div className="join-team-parker-form-inner">
                <h4>First Name</h4>
                <input value={emailReq.book_firstName} onChange={(e)=>{
                  setEmailReq({
                    ...emailReq,
                    book_firstName: e.target.value
                  })
                }} required/>
              </div>
              <div className="join-team-parker-form-inner">
                <h4>Last Name</h4>
                <input value={emailReq.book_lastName} onChange={(e)=>{
                  setEmailReq({
                    ...emailReq,
                    book_lastName: e.target.value
                  })
                }} required/>
              </div>
              <div className="join-team-parker-form-inner">
                <h4>Telephone Number</h4>
                <input value={emailReq.book_number} onChange={(e)=>{
                  setEmailReq({
                    ...emailReq,
                    book_number: e.target.value
                  })
                }} required/>
              </div>
              <div className="join-team-parker-form-inner">
                <h4>Email</h4>
                <input type={'email'} value={emailReq.book_email} onChange={(e)=>{
                  setEmailReq({
                    ...emailReq,
                    book_email: e.target.value
                  })
                }} required/>
              </div>
              { showSuccess ?
                <h6>Success, your form is submitted.</h6>
              :
                <button type="submit" disabled={fileData?.file ? false : true}>SUBMIT</button>
              }
            </form>
            <div className="join-team-parker-form-view">
              { fileData ?
              <>
                { fileData.file.type === "application/pdf" ?
                  <embed  
                    src={fileData.object}
                    className={'embed'}
                  />
                : null }
              </>
              : null }
              <div className="bottom-control">
                <h6>
                  { fileData ?
                    <>{fileData.file.name}</>
                  : null }
                </h6>
                <button className="file-upload">
                  <svg width="27" height="36" viewBox="0 0 27 36" fill="none">
                    <path d="M15.75 9.5625V0H1.6875C0.752344 0 0 0.752344 0 1.6875V34.3125C0 35.2477 0.752344 36 1.6875 36H25.3125C26.2477 36 27 35.2477 27 34.3125V11.25H17.4375C16.5094 11.25 15.75 10.4906 15.75 9.5625ZM20.333 24.7507H15.75V30.3757C15.75 30.9973 15.2466 31.5007 14.625 31.5007H12.375C11.7534 31.5007 11.25 30.9973 11.25 30.3757V24.7507H6.66703C5.66297 24.7507 5.16164 23.535 5.87461 22.827L12.6541 16.098C13.1217 15.6333 13.8769 15.6333 14.3445 16.098L21.124 22.827C21.8377 23.535 21.337 24.7507 20.333 24.7507ZM26.5078 7.38281L19.6242 0.492188C19.3078 0.175781 18.8789 0 18.4289 0H18V9H27V8.57109C27 8.12813 26.8242 7.69922 26.5078 7.38281Z" fill="white"/>
                  </svg><div>&nbsp;UPLOAD CV</div>
                  <input ref={fileinputEl} accept="application/pdf" className="file" type={'file'} onChange={(e)=>{
                    setFileData({ 
                      object: URL.createObjectURL(e.target.files[0]),
                      file: e.target.files[0]
                    });
                  }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </main>
  )
};

export default JoinTeamParker;