import Header from '../../../components/parker-in-action/header/header';
import Footer from '../../../components/parker-in-action/footer/footer';
import JoinUs from '../../../components/parker-in-action/join-us/join-us';
import Customer from '../../../components/parker-in-action/customer/customer';
import HowMeetparkerWorks from '../../../components/parker-in-action/how-meetparker-works/how-meetparker-works';
import UnfullImage from '../../../components/parker-in-action/unfull-image/unfull-image';
import Banner from './banner/banner';

const Portal = () => (
  <main>
    <Header title={'Portals'}/>
    <Banner />
    <Customer />
    <HowMeetparkerWorks/>
    <JoinUs />
    <Footer />
  </main>
);

export default Portal;