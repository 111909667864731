import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import './banner.css';

const Banner = () => (
  <section className="banner-hpac-portal-wrapper">
    <div className="banner-hpac-portal">
      <div className="banner-hpac-portal-image"></div>
      <div className="banner-hpac-portal-sign-in">
        <div className="banner-hpac-portal-sign-in-inner">
          <img src="https://mp-other-files.s3.eu-west-2.amazonaws.com/t5t47610-4t25-4664-7t20-600666112547.svg" />
          <br/><br/>
          <h2>Welcome back to MyPortal</h2>
          <p>Please enter your username and password</p>
          <br/>
          <div className="form-group">
            <label>Email</label>
            <input type={'text'} />
          </div>
          <br/>
          <div className="form-group">
            <label>Password</label>
            <input type={'password'} />
          </div>
          <br/>
          <button>Log In</button>
          <br/><br/>
          <p>Forgot Password? <a href="#">Try Again</a></p>
        </div>
      </div>
    </div>
  </section>
);

export default Banner;