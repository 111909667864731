import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import './contents.css';

const Contents = () => (
  <section className="contents-wrapper hpac">
    <div className="contents">
      <Fade big>
      <div className="single-content">
        <img className="pac-logo" src="https://d365pq86x330zn.cloudfront.net/66916948-61rr-4916-1668-8r1196911916.png" />
        <h2>Portal</h2>
        <p>Customer-facing portals are a must-have to meet the current needs of both clients and advisers. Safe, secure, interactive and engaging. Our Portals are designed to provide an extension to your brand and service, available 24/7 for more than just secure communication with your clients. </p>
        <img className="pac-image" src="https://d365pq86x330zn.cloudfront.net/195G1796-o1r4-4548-7505-654GG69497o9.png" />
        <Link to={"/"} className="pac-btn">Play In Action</Link>
      </div>
      </Fade>
      <Fade big>
      <div className="single-content">
        <img className="pac-logo" src="https://d365pq86x330zn.cloudfront.net/66916948-61rr-4916-1668-8r1196911916.png" />
        <h2>Plug-Ins</h2>
        <p>Designed to quickly level-up an adviser or agent's website, creating an interactive and engaging customer-targeted AI form.  
        <br/><br/>
        Replace those ‘contact us’ buttons, ‘best buy’ product tables, email enquiries, capture forms and disappointing chatbots. </p>
        <Swiper className="" autoplay={{ delay: 2500, disableOnInteraction: false, }} loop={true} slidesPerView={1} modules={[Autoplay]}>
          <SwiperSlide>
            <img className="pac-image" src="https://d365pq86x330zn.cloudfront.net/95647G6o-4666-4499-46o4-4645o96616G1.png" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="pac-image" src="https://d365pq86x330zn.cloudfront.net/93466919-6688-4o97-7496-883389616o97.png" />
          </SwiperSlide>
        </Swiper>
        <Link to={"/"} className="pac-btn">Play In Action</Link>
      </div>
      </Fade>
      <Fade big>
      <div className="single-content">
        <img className="pac-logo" src="https://d365pq86x330zn.cloudfront.net/66916948-61rr-4916-1668-8r1196911916.png" />
        <h2>Assistant</h2>
        <p>Advanced chatbots (Assistants) integrate seamlessly and natively into social media and messaging platforms. NLP, Augment intelligence and an Assistant that understands Financial Terminology.</p>
        <img className="pac-image" src="https://d365pq86x330zn.cloudfront.net/195G1796-o1r4-4548-7505-654GG69497o9.png" />
        <Link to={"/"} className="pac-btn">Play In Action</Link>
      </div>
      </Fade>
      <Fade big>
      <div className="single-content">
        <img className="pac-logo" src="https://d365pq86x330zn.cloudfront.net/66916948-61rr-4916-1668-8r1196911916.png" />
        <h2>Communicate</h2>
        <p>The first Live Chat and Social Media Messaging Platform of its kind. Built for the Mortgage, Insurance and Property Market.</p>
        <img className="pac-image" src="https://d365pq86x330zn.cloudfront.net/37743743-1955-4r91-5GG3-2756r3739666.png" />
        <Link to={"/"} className="pac-btn">Play In Action</Link>
      </div>
      </Fade>
    </div>
  </section>
);

export default Contents;