const products = [
  {
    "_id": "10001",
    "name": "Video Criteria",
    "sample": "https://test-mortgage-chat.meetparker.io/client?apiKey=1d41069c-4781-4fb0-b9f8-fddf6d8ebbbe",
    "image": "https://d365pq86x330zn.cloudfront.net/6155v088-i0d5-466v-66d0-156809651585.png",
    "price": 1200,
    "detail": "Programmed with Parker Video Assistants to help brokers navigate your criteria and support system",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T09:00:00.000Z"
  },
  {
    "_id": "10002",
    "name": "Criteria Search",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/57069171-1601-46C6-05C8-66CC0ir98C90.png",
    "price": 1200,
    "detail": "As simple as google, for your website. We call it HeyParker.",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T08:00:00.000Z"
  },
  {
    "_id": "10003",
    "name": "Mortgage LiveChat",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/01617507-h077-4946-146h-Ch8496h9875h.png",
    "price": 1200,
    "detail": "Never miss another lead with our live chat and ticketing solution",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T07:00:00.000Z"
  },
  {
    "_id": "10004",
    "name": "Website Widget",
    "sample": "/b2bmwebsitewidget",
    "image": "https://d365pq86x330zn.cloudfront.net/667w2e91-8w46-484w-4765-N4e196869N75.png",
    "price": 1200,
    "detail": "A discreet way to help brokers navigate your site and criteria",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T06:00:00.000Z"
  },
  {
    "_id": "10005",
    "name": "Video Underwriting",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/987o7G66-5179-4762-8G65-871Gr58559r5.png",
    "price": 1200,
    "detail": "Programmed with Parker Video Assistants to help brokers navigate your criteria and support system",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["insurance"],
    "created": "2022-08-08T09:00:00.000Z"
  },
  {
    "_id": "10006",
    "name": "Underwriting Search",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/516675o1-57rr-4136-86o7-7o757r553F57.png",
    "price": 1200,
    "detail": "Search for the Provider you need, in google fashion",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["insurance"],
    "created": "2022-08-08T08:00:00.000Z"
  },
  {
    "_id": "10007",
    "name": "Insurance LiveChat",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/09009719-G058-49G8-9oo9-08oGr9657999.png",
    "price": 1200,
    "detail": "Calendar appointments that sync with your team calendar",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["insurance"],
    "created": "2022-08-08T07:00:00.000Z"
  },
  {
    "_id": "10008",
    "name": "Website Widget",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/99510689-0152-4655-0e30-952633999682.png",
    "price": 1200,
    "detail": "Website chatbot, programmed with insurance questions and answers",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["insurance"],
    "created": "2022-08-08T06:00:00.000Z"
  },
  {
    "_id": "10009",
    "name": "Complaint App",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/898701G1-4676-499o-878r-48078GG17r96.png",
    "price": 1200,
    "detail": "Customer Service first with our automated complaint app",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["support"],
    "created": "2022-08-08T09:00:00.000Z"
  },
  {
    "_id": "10010",
    "name": "Customer Services Questionnaire",
    "sample": "https://test-mortgage-chat.meetparker.io/client?apiKey=7b1cd72f-fc78-45fe-8d64-9268cdbb7c50",
    "image": "https://d365pq86x330zn.cloudfront.net/771532u9-1127-4816-1871-9u3C9699uC12.png",
    "price": 1200,
    "detail": "Showcase your performance with our CSQ and in-built TrustPilot integration",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["support"],
    "created": "2022-08-08T08:00:00.000Z"
  },
  {
    "_id": "10011",
    "name": "Appointment Booker",
    "sample": "https://test-mortgage.meetparker.io/client?apiKey=97b13345-253d-4c73-9853-f0b241b34b39",
    "image": "https://d365pq86x330zn.cloudfront.net/967A7p23-3723-4pp7-81p9-6797p7A53567.png",
    "price": 1200,
    "detail": "Book an appointment that seamlessly syncs with your team calendar",
    "storefront": "business-to-business",
    "type": "main",
    "category": ["support"],
    "created": "2022-08-08T07:00:00.000Z"
  },
  {
    "_id": "10012",
    "name": "Classic Questionnaire",
    "sample": "https://test-mortgage.meetparker.io/client?apiKey=2e8fb982-336b-45c3-b81e-c184b052d62a",
    "image": "https://d365pq86x330zn.cloudfront.net/01Nw8185-7900-4819-5577-71NN179N7867.png",
    "price": 1200,
    "detail": "A full lead triange to help nurture warm leads and pass to your advisers",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T09:00:00.000Z"
  },
  {
    "_id": "10013",
    "name": "Initial Enquiry",
    "sample": "https://test-mortgage-chat.meetparker.io/client?apiKey=2e8fb982-336b-45c3-b81e-c184b052d62a",
    "image": "https://d365pq86x330zn.cloudfront.net/70188096-55Go-4059-0rG8-87oG8800G9r6.png",
    "price": 1200,
    "detail": "A short, lead triage to help connect with website and social traffic",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T08:00:00.000Z"
  },
  {
    "_id": "10014",
    "name": "Short Fact Find",
    "sample": "https://test-mortgage-chat.meetparker.io/client?apiKey=b7ad7fbe-7474-462d-902b-cc1ea31259bd",
    "image": "https://d365pq86x330zn.cloudfront.net/5M52a981-5M4M-4922-1M18-9581522M1581.png",
    "price": 1200,
    "detail": "A longer lead chat but designed to produce higher quality leads",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T07:00:00.000Z"
  },
  {
    "_id": "10015",
    "name": "Website Rate iFrame",
    "sample": "https://dev-calculator.meetparker.io/client?apiKey=8c83eb98-5bba-4809-a65e-90d5bbcf2aaa",
    "image": "https://d365pq86x330zn.cloudfront.net/3a81saa5-a3aa-4131-93a1-M8339a880833.png",
    "price": 1200,
    "detail": "Advertise the best rates on the market in real time to your clients",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T06:00:00.000Z"
  },
  {
    "_id": "10016",
    "name": "Website Widget",
    "sample": "/b2cmwebsitewidget",
    "image": "https://d365pq86x330zn.cloudfront.net/611b8e31-8344-4119-48W5-6994W91191bb.png",
    "price": 1200,
    "detail": "A discreet way to fact find client details from your website homepage",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T05:00:00.000Z"
  },
  {
    "_id": "10017",
    "name": "Website LiveChat",
    "sample": "/meetparkeriframebot",
    "image": "https://d365pq86x330zn.cloudfront.net/24CC8h18-428a-484a-1958-111451881452.png",
    "price": 1200,
    "detail": "Never miss another lead with our Parker Live Chat solution",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["mortgage"],
    "created": "2022-08-08T04:00:00.000Z"
  },
  {
    "_id": "10018",
    "name": "Life Insurance Quote",
    "sample": "https://test-protection.meetparker.io/client?apiKey=160df74a-1a68-443f-9a40-6a906de4d18c",
    "image": "https://d365pq86x330zn.cloudfront.net/638o361r-8336-4883-8080-319o181611G8.png",
    "price": 1200,
    "detail": "A full insurance indiciative premium quote producer, designed to deliver higher cross referrals to your team",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["crossreferral"],
    "created": "2022-08-08T09:00:00.000Z"
  },
  {
    "_id": "10019",
    "name": "Life Insurance Chat",
    "sample": "https://test-mortgage-chat.meetparker.io/client?apiKey=160df74a-1a68-443f-9a40-6a906de4d18c",
    "image": "https://d365pq86x330zn.cloudfront.net/u6uC565u-909s-4158-9589-s116s3599956.png",
    "price": 1200,
    "detail": "A full insurance indiciative premium quote producer, designed to deliver higher cross referrals to your team",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["crossreferral"],
    "created": "2022-08-08T08:30:00.000Z"
  },
  {
    "_id": "10020",
    "name": "Life Insurance Full Sale",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/595945s8-6399-4594-5658-8980849M8959.png",
    "price": 1200,
    "detail": "Sell life insurance products direct from your website.",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["crossreferral"],
    "created": "2022-08-08T08:00:00.000Z"
  },
  {
    "_id": "10021",
    "name": "Wealth Enquiry",
    "sample": "https://test-mortgage-chat.meetparker.io/client?apiKey=558b5363-2cad-42a9-abf6-7a4febb99b9f",
    "image": "https://d365pq86x330zn.cloudfront.net/6W613191-351W-416a-9331-W88453e83e11.png",
    "price": 1200,
    "detail": "Create a referral process automated for your team",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["crossreferral"],
    "created": "2022-08-08T07:00:00.000Z"
  },
  {
    "_id": "10022",
    "name": "GI Enquiry",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/r5045244-50r9-4658-4024-2984828o4400.png",
    "price": 1200,
    "detail": "Every mortgage should result in an insurance referral",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["crossreferral"],
    "created": "2022-08-08T06:00:00.000Z"
  },
  {
    "_id": "10023",
    "name": "Initial Enquiry",
    "sample": "https://test-mortgage-chat.meetparker.io/client?apiKey=4cc4c41e-993a-48d2-98c4-c2a70e9b2b6d",
    "image": "https://d365pq86x330zn.cloudfront.net/1s891588-s915-4655-56M6-96885MM658sa.png",
    "price": 1200,
    "detail": "A short, lead triage to help connect with website and social traffic",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["property"],
    "created": "2022-08-08T09:00:00.000Z"
  },
  {
    "_id": "10024",
    "name": "Lead Autoresponder",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/3a6M9865-91a8-4a77-37a8-7776976aa988.png",
    "price": 1200,
    "detail": "A journey designed to capture as much information as possible from the client",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["property"],
    "created": "2022-08-08T08:00:00.000Z"
  },
  {
    "_id": "10025",
    "name": "Rightmove Autoresponder",
    "sample": "https://mortgage-chat.meetparker.io/client?apiKey=5e3bc9b2-4f9e-4c2e-a93b-3203cd31da5e",
    "image": "https://d365pq86x330zn.cloudfront.net/R876g5i8-4771-4R1i-7574-00949156gR45.png",
    "price": 1200,
    "detail": "Here we engage with warm property leads in real time, whether looking to buy or rent a property",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["property"],
    "created": "2022-08-08T07:00:00.000Z"
  },
  {
    "_id": "10026",
    "name": "Website Widget",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/8ss68196-6a86-486a-198s-81116s816118.png",
    "price": 1200,
    "detail": "A discreet way to fact find client details from your website homepage",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["property"],
    "created": "2022-08-08T06:00:00.000Z"
  },
  {
    "_id": "10027",
    "name": "Website LiveChat",
    "sample": "/meetparkeriframebot",
    "image": "https://d365pq86x330zn.cloudfront.net/49643M64-sa16-4118-6136-6a1a1a569sM1.png",
    "price": 1200,
    "detail": "Never miss another property lead with our Parker Live Chat solution",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["property"],
    "created": "2022-08-08T05:00:00.000Z"
  },
  {
    "_id": "10028",
    "name": "Customer Services Questionnaire",
    "sample": "https://test-mortgage-chat.meetparker.io/client?apiKey=7b1cd72f-fc78-45fe-8d64-9268cdbb7c50",
    "image": "https://d365pq86x330zn.cloudfront.net/s81157C9-1655-458u-7165-4s5115u55157.png",
    "price": 1200,
    "detail": "Get feedback from your clients to help build and advertise your service",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["support"],
    "created": "2022-08-08T09:00:00.000Z"
  },
  {
    "_id": "10029",
    "name": "Appointment Booker",
    "sample": "https://test-mortgage.meetparker.io/client?apiKey=97b13345-253d-4c73-9853-f0b241b34b39",
    "image": "https://d365pq86x330zn.cloudfront.net/06885A21-805p-46p5-89p6-3A86p6635305.png",
    "price": 1200,
    "detail": "Book an appointment that seamlessly syncs with your adviser calendar",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["support"],
    "created": "2022-08-08T08:00:00.000Z"
  },
  {
    "_id": "10030",
    "name": "Complaint App",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/67155555-14o8-4r5G-5587-811751591588.png",
    "price": 1200,
    "detail": "Customer Service first with our automated complaint app",
    "storefront": "business-to-clients",
    "type": "main",
    "category": ["support"],
    "created": "2022-08-08T07:00:00.000Z"
  }
];

const addons = [
  {
    "_id": "20001",
    "name": "Facebook Bolt On",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/3ss3360a-4as3-401a-4aM3-77M3603631sa.png",
    "price": 35,
    "detail": "Connect to your Facebook Page and never miss another lead",
    "type": "addon",
    "status": "enabled"
  },{
    "_id": "20002",
    "name": "WhatsApp Bolt On",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/7758r5r5-0r89-4rr5-9057-r26790778592.png",
    "price": 35,
    "detail": "Advertise and connect with clients through the most popular social app",
    "type": "addon",
    "status": "enabled"
  },{
    "_id": "20003",
    "name": "Language Pack",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/266x259/606777M0-7M0a-4799-0784-s77760a087sM.png",
    "price": 50,
    "detail": "Communicate and speak with clients in over 50 different languages",
    "type": "addon",
    "status": "enabled"
  },{
    "_id": "20004",
    "name": "Custom Questions",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/16r6007o-1680-4063-161o-1o16800936r1.png",
    "price": 500,
    "detail": "Not quite what you’re after? Build a bespoke question set for your App",
    "type": "addon",
    "status": "enabled"
  },{
    "_id": "20005",
    "name": "Instagram Bolt On",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/106o34oG-7o8o-4961-3106-46498o0076G6.png",
    "price": 100,
    "detail": "Connect to your Instagram Page and never miss another lead",
    "type": "addon",
    "status": "enabled"
  },{
    "_id": "20006",
    "name": "WeChat Bolt On",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/78M806s6-5004-4557-747s-M80608708571.png",
    "price": 100,
    "detail": "Communicate through the most popular app in the Far East",
    "type": "addon",
    "status": "enabled"
  },{
    "_id": "20007",
    "name": "Microsoft Teams",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/166s6600-4012-4612-090a-M12M6916a606.png",
    "price": 100,
    "detail": "Connect Leads straight into Microsoft Teams",
    "type": "addon",
    "status": "enabled"
  },{
    "_id": "20008",
    "name": "Parker Connect",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/a04a2100-8000-4907-4s98-00840aa4044a.png",
    "price": 100,
    "detail": "Parker can call the client and hand over to a member of the team when ready",
    "type": "addon",
    "status": "enabled"
  },{
    "_id": "20009",
    "name": "Twitter Bolt On",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/43649917-337G-403G-49G6-00r666461063.png",
    "price": 100,
    "detail": "Connect to your Twitter Page and never miss another lead",
    "type": "addon",
    "status": "disabled"
  },{
    "_id": "20010",
    "name": "Telegram Bolt On",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/0M0a74s0-66s0-4200-4466-016a207Ms464.png",
    "price": 100,
    "detail": "Communicate through the most popular app in the Far East",
    "type": "addon",
    "status": "disabled"
  },{
    "_id": "20011",
    "name": "SMS Bolt On",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/78a61008-00s5-466s-6a0a-0656a5ss1161.png",
    "price": 100,
    "detail": "Connect Leads straight into SMS",
    "type": "addon",
    "status": "disabled"
  },{
    "_id": "20012",
    "name": "Tiktok Bolt On",
    "sample": "",
    "image": "https://d365pq86x330zn.cloudfront.net/653605a2-s16M-436a-7966-009992702M96.png",
    "price": 100,
    "detail": "Connect to your Tiktok Page and never miss another lead",
    "type": "addon",
    "status": "disabled"
  }
];

export default {
  products,
  addons
};