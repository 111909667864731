import Header from '../../components/header/header';
import CommunicateBanner from '../../components/communicate-banner/communicate-banner';
import CallToActions from '../../components/call-to-actions/call-to-actions';
import GameChanging from '../../components/game-changing/game-changing';
import WantKnowMore from '../../components/want-know-more/want-know-more';
import MultiAward from '../../components/multi-award/multi-award';
import Footer from '../../components/footer/footer';

import './communicate.scss'

const bannerContent = [
  {
    type: 'element',
    element: <><img src={'https://d365pq86x330zn.cloudfront.net/161611e1-N6NN-4167-8101-09Ne1170117w.png'} /></>,
  },
  {
    type: 'details',
    title: 'A New Way to Communicate with Clients',
    desc: 'Create more Opportunities or streamline the enquiries you receive.',
    buttons: [
      {
        type: 'secondary',
        url: 'https://calendar.meetparker.co.uk/client?apiKey=8849dbd4-d27d-4cba-8017-22f1c98e3c3b',
        text: 'Book a demo',
      },
    ]
  },
];

const callToActions = [
  {
    image: 'https://d365pq86x330zn.cloudfront.net/0L27i7L5-i006-4666-9597-09512667332i.png',
    details: {
      title: 'Communicate Outbound',
      desc: 'Through Branded Emails, SMS, WhatsApp and AI Telephone Calls.',
      link: {
        url: '/communicate-outbound',
        text: 'LEARN MORE'
      }
    }
  },
  {
    image: 'https://d365pq86x330zn.cloudfront.net/37979706-8696-407o-9G1G-1r068rr2869o.png',
    details: {
      title: 'Communicate Inbound',
      desc: 'Interactive Pages where AI completes the sale, feeding data to your CRM.',
      link: {
        url: '/communicate-inbound',
        text: 'LEARN MORE'
      }
    }
  }
];

const Communicate = () => (<main>
  <Header/>
  <CommunicateBanner contents={bannerContent} />
  <CallToActions callToActions={callToActions} />
  <GameChanging />
  <WantKnowMore />
  <MultiAward />
  <Footer/>
</main>);

export default Communicate;